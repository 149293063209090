import * as s from './ArCookieBar.module.scss'

import React, { useEffect, useState } from 'react'

import { ArButton } from 'src/components/ArButton'
import { Link } from 'gatsby'
import { ROUTES } from 'src/config/routes'
import { TArCookieBarProps } from './ArCookieBar.types'

const LS_COOKIES = 'COOKIES_STATE'

function gtag(...args: any[]) {
  window && window.dataLayer && window.dataLayer.push(...args)
}

function deleteAllCookies() {
  if (!window.document) return
  var cookies = window.document.cookie.split(';')

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i]
    var eqPos = cookie.indexOf('=')
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    window.document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}

export const ArCookieBar: React.FC<TArCookieBarProps> = () => {
  const [state, setState] = useState(typeof window !== 'undefined' && window.localStorage?.getItem(LS_COOKIES))

  const accept = () => {
    setState('accepted')
    window.localStorage.setItem(LS_COOKIES, 'accepted')
    gtag('consent', 'default', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    })
  }

  const reject = () => {
    setState('rejected')
    gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    })
    deleteAllCookies()
  }

  useEffect(() => {
    if (state) {
      window.localStorage.setItem(LS_COOKIES, state)
    }
  }, [state])

  if (typeof state === 'string') return null

  return (
    <div className={s.container}>
      <p>
        We and selected third parties use cookies or similar technologies as specified in the cookie policy.{' '}
        <Link to={ROUTES.privacyPolicy()}>Learn more</Link>
      </p>
      <ArButton theme="primary" small onClick={accept}>
        Accept
      </ArButton>
      <ArButton theme="white" small onClick={reject}>
        Reject
      </ArButton>
    </div>
  )
}
