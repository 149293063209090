import { useCallback, useEffect, useRef, useState } from 'react'

import { METRICS } from 'src/config/metrics'
import { PLATFORM_PRODUCTS } from 'src/config/platformProducts'
import { throttle } from 'lodash'
import useDeviceDetect from 'src/utils/useDeviceDetect'

export const useProductsSectionDetect = () => {
  const { isSmallDevice } = useDeviceDetect()
  const ids = PLATFORM_PRODUCTS.map((item) => item.className)
  const elements = useRef<(HTMLElement | null)[]>()
  const [activeElement, setActiveElement] = useState<HTMLElement>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScroll = useCallback(
    throttle(() => {
      const detector = window.scrollY + window.innerHeight * 0.3
      let updated = false
      elements.current?.forEach((item) => {
        if (!item) return
        if (
          item.offsetTop < detector &&
          item.offsetTop + item.clientHeight > detector &&
          window.scrollY > (!isSmallDevice ? METRICS.PlatformHeroHeightDesktop : METRICS.PlatformHeroHeightMobile)
        ) {
          setActiveElement(item)
          updated = true
        }
      })
      if (!updated) {
        setActiveElement(undefined)
      }
    }, 50),
    [],
  )

  useEffect(() => {
    // Initial fire
    onScroll()
    elements.current = PLATFORM_PRODUCTS.map(({ id }) => document.getElementById(id))

    document.addEventListener('scroll', onScroll)
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [ids, onScroll])

  return activeElement
}
