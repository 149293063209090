import * as s from './ArPlatformMobileProductsMenu.module.scss'

import React, { useEffect, useState } from 'react'

import { PLATFROM_SECTIONS } from 'src/config/platformSections'
import { ROUTES } from 'src/config/routes'
import SvgArrowRight from 'src/assets/svg/arrow-right.svg'
import { TArPlatformMobileProductsMenuProps } from './ArPlatformMobileProductsMenu.types'
import classNames from 'classnames'
import { isPage } from 'src/utils/isPage'
import { scroller } from 'react-scroll'
import useDeviceDetect from 'src/utils/useDeviceDetect'
import { useLocation } from '@reach/router'
import { useProductsSectionDetect } from 'src/hooks/useProductsSectionDetect'

export const ArPlatformMobileProductsMenu: React.FC<TArPlatformMobileProductsMenuProps> = () => {
  const { isSmallDevice } = useDeviceDetect()
  const location = useLocation()
  const visibileSectionItem = useProductsSectionDetect()

  const [showMenu, setShowMenu] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const activeItemIndex = PLATFROM_SECTIONS.findIndex((item) => item.id === visibileSectionItem?.id)
  const onItemClick = (item: any) => {
    if (expanded) {
      let offset = 0
      switch (item.id) {
        case 'data-ingestion':
        case 'contract-builder':
        case 'risk-traging':
        case 'underwriting-workbench':
          offset = -20
          break
        case 'integrations':
          offset = 20
          break
      }
      scroller.scrollTo(item.id, {
        duration: 1000,
        smooth: true,
        offset,
      })
      setExpanded(false)
    } else {
      setExpanded(true)
    }
  }

  useEffect(() => {
    if (!visibileSectionItem && expanded) {
      setExpanded(false)
    }
  }, [expanded, visibileSectionItem])

  useEffect(() => {
    const isPlatformPage = isPage(ROUTES.platform(), location.pathname)
    setShowMenu(isPlatformPage && isSmallDevice)
  }, [isSmallDevice, location])

  if (!showMenu) return null

  return (
    <div
      className={classNames(s.container, {
        [s.show]: activeItemIndex >= 0,
        [s.expanded]: expanded,
      })}>
      <button title="Toggle" className={s.arrowButton} onClick={() => setExpanded(!expanded)}>
        <SvgArrowRight />
      </button>
      <ul style={{ transform: `translateY(${expanded ? 0 : -activeItemIndex * 48}px)` }}>
        {PLATFROM_SECTIONS.map((item, i) => (
          <li key={i}>
            <button
              className={classNames({
                [s.active]: activeItemIndex === i,
              })}
              onClick={() => onItemClick(item)}>
              {item.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
