import React, { useState } from 'react'
import { useJobsData } from 'src/hooks/useJobsData'

type TAppContextValue = {
  isMobileMenuOpen: boolean
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  jobsData: { title: string; url: string }[]
}

const defaultValue: TAppContextValue = {
  isMobileMenuOpen: false,
  setIsMobileMenuOpen: () => {},
  jobsData: [],
}

export const AppContext = React.createContext<TAppContextValue>(defaultValue)

export const AppContextProvider: React.FC = ({ children }) => {
  const { data: jobsData } = useJobsData()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(defaultValue.isMobileMenuOpen)

  return (
    <AppContext.Provider
      value={{
        isMobileMenuOpen,
        setIsMobileMenuOpen,
        jobsData,
      }}>
      {children}
    </AppContext.Provider>
  )
}
