import * as s from './ArInternetExploderModal.module.scss'

import React, { useState } from 'react'

import { ArButton } from 'src/components/ArButton'
import SvgExclamationCircle from 'src/assets/svg/exclamation-circle.svg'
import { TArInternetExploderModalProps } from './ArInternetExploderModal.types'

export const ArInternetExploderModal: React.FC<TArInternetExploderModalProps> = () => {
  const [hide, setHide] = useState(false)

  const upgradeBrowser = () => {
    setHide(true)
    window.open('http://browserupdate.org/', '_blank').focus()
  }

  if (hide) return null

  return (
    <>
      <div className={s.overlayer} />
      <div className={s.container}>
        <h1>
          <SvgExclamationCircle />
          Outdated / Unsupported browser
        </h1>
        <p>
          Artificial no longer offers support for this browser. Please consider upgrading to a more modern alternative.
        </p>
        <div>
          <ArButton theme="primary" medium onClick={upgradeBrowser}>
            Upgrade my browser
          </ArButton>
          <ArButton theme="white" medium onClick={() => setHide(true)}>
            Reject
          </ArButton>
        </div>
      </div>
    </>
  )
}
