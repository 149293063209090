import { PLATFORM_PRODUCTS } from 'src/config/platformProducts'
import React from 'react'
import SvgAlgoUnderwriting from 'src/assets/svg/algo-underwriting.svg'
import SvgApiV2 from 'src/assets/svg/apiv2.svg'
import SvgContractBuilder from 'src/assets/svg/contract-builder.svg'
import SvgGrayscaleAlgoUnderwriting from 'src/assets/svg/algo-underwriting-gray.svg'
import SvgGrayscaleApiV2 from 'src/assets/svg/apiv2-gray.svg'
import SvgGrayscaleContractBuilder from 'src/assets/svg/contract-builder-gray.svg'
import SvgGrayscaleIngestion from 'src/assets/svg/ingestion-gray.svg'
import SvgGrayscaleRapidTriage from 'src/assets/svg/rapid-triage-gray.svg'
import SvgGrayscaleWorkbench from 'src/assets/svg/workbench-gray.svg'
import SvgIngestion from 'src/assets/svg/ingestion.svg'
import SvgRapidTriage from 'src/assets/svg/rapid-triage.svg'
import SvgSmallAlgoUnderwriting from 'src/assets/svg/algo-underwriting-small.svg'
import SvgSmallApiV2 from 'src/assets/svg/apiv2-small.svg'
import SvgSmallContractBuilder from 'src/assets/svg/contract-builder-small.svg'
import SvgSmallIngestion from 'src/assets/svg/ingestion-small.svg'
import SvgSmallRapidTriage from 'src/assets/svg/rapid-triage-small.svg'
import SvgSmallWorkbench from 'src/assets/svg/workbench-small.svg'
import SvgWorkbench from 'src/assets/svg/workbench.svg'

export const PLATFROM_SECTIONS = [
  {
    title: 'Algorithmic underwriting',
    subtitle: 'Lead the market with Artificial’s algorithmic underwriting platform.',
    id: PLATFORM_PRODUCTS[0].id,
    colorfulIcon: <SvgAlgoUnderwriting />,
    colorfulIconSmall: <SvgSmallAlgoUnderwriting />,
    grayscaleIconSmall: <SvgGrayscaleAlgoUnderwriting />,
  },
  {
    title: 'Data ingestion',
    subtitle: 'Say goodbye to manual data entry with automatic, real-time extraction.',
    id: PLATFORM_PRODUCTS[1].id,
    colorfulIcon: <SvgIngestion />,
    colorfulIconSmall: <SvgSmallIngestion />,
    grayscaleIconSmall: <SvgGrayscaleIngestion />,
  },
  {
    title: 'Instant risk triaging',
    subtitle: 'Triage risks in seconds with automated decision making.',
    id: PLATFORM_PRODUCTS[2].id,
    colorfulIcon: <SvgRapidTriage />,
    colorfulIconSmall: <SvgSmallRapidTriage />,
    grayscaleIconSmall: <SvgGrayscaleRapidTriage />,
  },
  {
    title: 'Contract builder',
    subtitle: 'Build digital contracts with structured data, aligned to market standards.',
    id: PLATFORM_PRODUCTS[3].id,
    colorfulIcon: <SvgContractBuilder />,
    colorfulIconSmall: <SvgSmallContractBuilder />,
    grayscaleIconSmall: <SvgGrayscaleContractBuilder />,
  },
  {
    title: 'Underwriting workbench',
    subtitle: 'Powerful underwriting with human-in-the-loop decision making and support.',
    id: PLATFORM_PRODUCTS[4].id,
    colorfulIcon: <SvgWorkbench />,
    colorfulIconSmall: <SvgSmallWorkbench />,
    grayscaleIconSmall: <SvgGrayscaleWorkbench />,
  },
  {
    title: 'Integrations & API',
    subtitle: 'Effortlessly integrate data with internal and external sources via API.',
    id: PLATFORM_PRODUCTS[5].id,
    colorfulIcon: <SvgApiV2 />,
    colorfulIconSmall: <SvgSmallApiV2 />,
    grayscaleIconSmall: <SvgGrayscaleApiV2 />,
  },
]
