import * as s from './ArButton.module.scss'

import ArrowRight from 'src/assets/svg/arrow-right.svg'
import React from 'react'
import { TArButtonProps } from './ArButton.types'
import classNames from 'classnames'

export const ArButton: React.FC<TArButtonProps> = ({
  className,
  style,
  children,
  onClick,
  theme,
  id,
  type,
  small,
  medium,
}) => {
  const buttonClass = classNames(className, s.container, {
    [s.themePrimary]: theme === 'primary',
    [s.themeWhite]: theme === 'white',
    [s.themeDark]: theme === 'dark',
    [s.themeGradient]: theme === 'gradient',
    [s.themeGlowRed]: theme === 'glow-red',
    [s.small]: small,
    [s.medium]: medium,
  })

  const showArrow = !small && !medium && theme !== 'glow-red'

  return (
    <button type={type || 'button'} className={buttonClass} onClick={onClick} style={style} id={id}>
      <span>{children}</span>
      {showArrow && <ArrowRight />}
    </button>
  )
}
