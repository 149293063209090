import { useEffect, useState } from 'react'
import { JOBS_LIST_URL } from 'src/config/constants'

export function useJobsData() {
  const [jobsData, setJobsData] = useState<{ title: string; url: string }[]>([])

  const fetchJobs = async () => {
    try {
      const response = await fetch(JOBS_LIST_URL, {
        method: 'GET',
      })
      const data = await response.json()

      if (response.ok && data) {
        setJobsData(
          data.jobs.map((item: any) => ({
            title: item.title,
            url: item.url,
          })),
        )
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchJobs()
  }, [])

  return {
    data: jobsData,
  }
}
