import { AppContextProvider } from 'src/contexts/AppContext'
import { ArLayout } from 'src/components/ArLayout'
import React from 'react'

const Layout: React.FC = (props) => (
  <AppContextProvider>
    <ArLayout {...props} />
  </AppContextProvider>
)

export default Layout
