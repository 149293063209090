import React from 'react'

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false)
  const [isSmallDevice, setIsSmallDevice] = React.useState(false)
  const [isIE, setIsIE] = React.useState(false)

  React.useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent
    const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i))
    const smallDevice = mobile || userAgent ? window!.innerWidth < 768 : false
    const IE = !!userAgent.match(/Trident.*rv\:11\./)
    setMobile(mobile)
    setIsSmallDevice(smallDevice)
    setIsIE(IE)
  }, [])

  return { isMobile, isSmallDevice, isIE }
}
