import * as s from './ArContainer.module.scss'

import React from 'react'
import { TArContainerProps } from './ArContainer.types'
import classNames from 'classnames'

export const ArContainer: React.FC<TArContainerProps> = ({
  children,
  className,
  style,
  full,
  contentWrapperClassName,
}) => {
  const containerClass = classNames(s.container, className, {
    [s.full]: full,
  })

  return (
    <div className={containerClass} style={style}>
      {full ? <div className={contentWrapperClassName}>{children}</div> : children}
    </div>
  )
}
