import * as s from './ArTopNavProducts.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import { METRICS } from 'src/config/metrics'
import { PLATFROM_SECTIONS } from 'src/config/platformSections'
import React from 'react'
import { TArTopNavProductsProps } from './ArTopNavProducts.types'
import classNames from 'classnames'
import { scroller } from 'react-scroll'

export const ArTopNavProducts: React.FC<TArTopNavProductsProps> = ({ show, style, activeItemId }) => {
  const containerClass = classNames(s.container, {
    [s.show]: show,
  })

  const scrollToItem = (id: string) => {
    let offset = 0

    switch (id) {
      case 'data-ingestion':
      case 'contract-builder':
      case 'underwriting-workbench':
        offset = -(METRICS.TopNavHeight / 2)
        break
      case 'risk-traging':
      case 'algorithmic-underwriting':
        offset = -(METRICS.TopNavHeight * 1.5)
        break
    }
    scroller.scrollTo(id, {
      duration: 1000,
      smooth: true,
      offset,
    })
  }

  return (
    <ArContainer className={containerClass} style={style}>
      {PLATFROM_SECTIONS.map((item) => {
        const isActive = item.id === activeItemId || undefined
        return (
          <button key={item.id} className={isActive && s.active} onClick={() => scrollToItem(item.id)}>
            <div>{isActive ? item.colorfulIconSmall : item.grayscaleIconSmall}</div>
            <span>{item.title}</span>
            <span>{item.title}</span>
          </button>
        )
      })}
    </ArContainer>
  )
}
