import ImgAlgorithmicUnderwriting from 'src/assets/images/AlgorithmicUnderwriting.png'
import ImgAlgorithmicUnderwritingWebp from 'src/assets/images/AlgorithmicUnderwriting.webp'
import ImgContractBuilder from 'src/assets/images/ContractBuilder.png'
import ImgContractBuilderWebp from 'src/assets/images/ContractBuilder.webp'
import ImgDataIngestion from 'src/assets/images/DataIngestion.png'
import ImgDataIngestionWebp from 'src/assets/images/DataIngestion.webp'
import ImgFlexibleIntegrations from 'src/assets/images/FlexibleIntegrations.png'
import ImgFlexibleIntegrationsWebp from 'src/assets/images/FlexibleIntegrations.webp'
import ImgRiskTriage from 'src/assets/images/RiskTriage.png'
import ImgRiskTriageWebp from 'src/assets/images/RiskTriage.webp'
import ImgUnderwritingWorkbench from 'src/assets/images/UnderwritingWorkbench.png'
import ImgUnderwritingWorkbenchWebp from 'src/assets/images/UnderwritingWorkbench.webp'
import React from 'react'
import SvgCloudDone from 'src/assets/svg/cloud-done.svg'
import SvgLock from 'src/assets/svg/lock.svg'
import SvgPlug from 'src/assets/svg/plug.svg'
import SvgVirtualAssistant from 'src/assets/svg/virtual-assistant.svg'

export const PLATFORM_PRODUCTS = [
  {
    id: 'algorithmic-underwriting',
    className: 'algorithmicUnderwriting',
    textWithImageProps: {
      title: 'Algorithmic underwriting',
      paragraph: [
        {
          type: 'p',
          value:
            "Experience the future of insurance and trade algorithmically using  Artificial's Underwriting Platform.",
        },
        { type: 'br' },
        {
          type: 'p',
          value:
            'Scale rapidly and configure to your business needs. Respond automatically to simple risks and give your underwriters more time to review complex submissions.',
        },
      ],
      image: {
        png: ImgAlgorithmicUnderwriting,
        webp: ImgAlgorithmicUnderwritingWebp,
      },
    },
    extraFeaturesProps: {
      columns: [
        {
          title: 'Smart follow',
          paragraph: [
            'As the ',
            { type: 'span', value: 'only smart follow provider ' },
            'with sophisticated data ingestion capabilities, Artificial uses machine learning to leverage data from submissions and make automated underwriting decisions based on your appetite.',
          ],
        },
        {
          title: 'Smart lead',
          paragraph:
            'Artificial gives you the power to combine submission data with internal and external sources to support the decision making process.',
        },
      ],
    },
  },
  {
    id: 'data-ingestion',
    className: 'dataIngestion',
    textWithImageProps: {
      title: 'Effortless data ingestion',
      paragraph: [
        {
          type: 'p',
          value:
            'Say goodbye to manual data entry. Artificial’s insurance-specific machine learning extraction tools enable automatic, real-time data extraction and validation from a wide range of sources.',
        },
      ],
      image: {
        png: ImgDataIngestion,
        webp: ImgDataIngestionWebp,
      },
      list: [
        'MRC slip policies',
        'Bordereaux files',
        'Schedules of value and loss runs',
        'PDFs, emails and attachments',
        'Internal and third-party data integrations',
      ],
      textOnTheLeft: true,
    },
  },
  {
    id: 'risk-traging',
    className: 'instantRiskTraging',
    textWithImageProps: {
      title: 'Instant risk triaging',
      image: {
        png: ImgRiskTriage,
        webp: ImgRiskTriageWebp,
      },
      paragraph: [
        {
          type: 'p',
          value:
            'Underwrite any time, any place with automated submission triaging. Accept submissions through various channels in multiple formats and provide clients and brokers with an answer in seconds.',
        },
        { type: 'br' },
        {
          type: 'p',
          value:
            'See every risk as part of the bigger picture thanks to our portfolio-based approach. Adjust your appetite according to loss ratios, market trends and even machine learning-based predictions.',
        },
      ],
    },
  },
  {
    id: 'contract-builder',
    className: 'contractBuilder',
    textWithImageProps: {
      title: 'Powerful digital contract builder',
      image: {
        png: ImgContractBuilder,
        webp: ImgContractBuilderWebp,
      },
      paragraph: [
        {
          type: 'p',
          value:
            'Bring the future of insurance one step closer by building market standard digital contracts. Combine your risk model, data points and legal documents with powerful editing and formatting capabilities for the ultimate insurance workflow.',
        },
      ],
      list: [
        'Generate policy documentation',
        'Negotiate, quote and bind in real time',
        'Enrich contracts via API (sanctions, currency conversion etc)',
      ],
      textOnTheLeft: true,
    },
  },
  {
    id: 'underwriting-workbench',
    className: 'underwritingWorkbench',
    textWithImageProps: {
      title: 'Underwriting workbench',
      image: {
        png: ImgUnderwritingWorkbench,
        webp: ImgUnderwritingWorkbenchWebp,
      },
      paragraph: [
        {
          type: 'p',
          value:
            'Control the full policy lifecycle with the Artificial end-to-end underwriting workbench. Process and structure all your received data to the necessary standards for use downstream.',
        },
        { type: 'br' },
        {
          type: 'p',
          value:
            'Artificial’s human-in-the-loop technology fully informs underwriters and brokers when submissions have been declined or referred, so no-one is kept in the dark.',
        },
      ],
      list: [
        'Compliance checks (sanctions, exposure, aggregations etc)',
        'Cancellations, endorsements and renewals',
        'Portfolio analytics and dashboards',
      ],
    },
  },
  {
    id: 'integrations',
    className: 'integrationsAndApi',
    extraFeaturesProps: {
      columns: [
        {
          title: 'Flexible integrations',
          paragraph:
            'Artificial makes it easy to retrieve data from integrated internal and external sources via API or data transfer.',
          image: {
            png: ImgFlexibleIntegrations,
            webp: ImgFlexibleIntegrationsWebp,
          },
        },
        {
          title: 'More great features',
          tiles: [
            {
              title: 'ISO 27001',
              paragraph: 'We are a certified ISO 27001 company.',
              icon: <SvgLock />,
            },
            {
              title: 'Client support',
              paragraph: 'With you every step of the journey.',
              icon: <SvgVirtualAssistant />,
            },
            {
              title: 'Cloud native',
              paragraph: 'To give you the best experience possible.',
              icon: <SvgCloudDone />,
            },
            {
              title: 'Fast & secure API',
              paragraph: 'Quickly and securely transfer data.',
              icon: <SvgPlug />,
            },
          ],
        },
      ],
    },
  },
]
