// Remember about slash at the end of route string
export const ROUTES = {
  homepage: () => '/',
  brokers: () => '/brokers/',
  platform: (id?: string) => `/underwriting-platform${id ? `#${id}` : '/'}`,
  about: (id?: string) => '/about' + (id ?? '/'),
  careers: () => '/careers/',
  blog: () => '/company/blog/',
  blogPost: (slug: string) => `/company/blog/${slug}/`,
  contact: () => '/contact/',
  security: () => '/security/',
  eula: () => '/eula/',
  privacyPolicy: () => '/privacy/',
}
